<template>
   <div class="container-register-sleep">
      <SWNavButton class="btn-nav-ragister" :to="{ name: 'home' }" :size="'small'">Back</SWNavButton>
      <div class="box-register-sleep">
         <div class="img-sleep-register">
            <img src="../assets/img/sleepNewLogo.png" alt="" />
         </div>

         <div class="title-sleep-register">
            <p>Welcome to</p>
            <p>Sleep-well.coach</p>
         </div>

         <div class="paragraf-register">Sign up with</div>
         <div class="button-social">
            <SWButton class="btn-reg" variant="secondary" size="small" :href="googleUrl">Google</SWButton>
            <SWButton class="btn-reg" variant="secondary" size="small" :href="twitterUrl"> Twitter </SWButton>
            <SWButton :href="facebookUrl" class="btn-reg" variant="secondary" size="small"> Facebook </SWButton>
         </div>
         <div class="paragraf-register">or</div>
         <!-- <router-view></router-view> -->
         <form @submit.prevent="submitHandler">
            <div class="register-input">
               <TheInput
                  class="margin-inp"
                  v-model="emailOrPhone"
                  :type="inputType"
                  :name="isValidEmail ? 'email' : isValidPhone ? 'phone' : 'email'"
                  :isError="emailOrPhone.length > 0 && !isValidEmail && !isValidPhone"
                  :errorMessage="errorImailorPhone ? errorImailorPhone : null"
                  placeholder="Email or Phone Number"
               ></TheInput>
               <TheInput
                  class="margin-inp"
                  placeholder="Password"
                  v-model="password"
                  type="password"
                  @input="$v.password.$touch()"
                  :errorMessage="passwordError"
                  :isError="$v.password.$error && !!passwordError"
                  :hasShowIcon="true"
               ></TheInput>
               <SWButton :disabled="disabled" type="submit" variant="primari" size="big">Log in</SWButton>
            </div>
         </form>
         <div class="footer-sleep-register">
            <div class="paragraf">
               <p>Signing in for a Sleep-well.coach account means you agree to the</p>
            </div>
            <FooterRegistartion />
         </div>
      </div>
   </div>
</template>

<script>
import { SWNavButton } from 'sw-ui-kit';
import { SWButton } from 'sw-ui-kit';
import TheInput from '../components/UI/TheInput.vue';
import FooterRegistartion from '../components/FooterRegistartion.vue';
import { validationMixin } from 'vuelidate';
import { required, minLength, maxLength } from 'vuelidate/lib/validators';
// import { required, minLength, maxLength, email } from 'vuelidate/lib/validators';
import { hasPassword } from '../validation/validation';
import appUrl from '../store/appUrl';

export default {
   components: {
      SWNavButton,
      SWButton,
      TheInput,
      FooterRegistartion,
   },
   mixins: [validationMixin],
   data() {
      return {
         emailOrPhone: '',
         inputType: 'text',
         password: null,
         phone: null,
         email: '',
         googleUrl: null,
         twitterUrl: null,
         facebookUrl: null,
      };
   },
   validations: {
      password: { required, hasPassword, minLength: minLength(8), maxLength: maxLength(20) },
   },

   computed: {
      isValidEmail() {
         const email =
            /^[A-Za-z0-9!#$%&‘*+=?^_`{|}~]+(\.[A-Za-z0-9!#$%&‘*+=?^_`{|}~]+)*@[A-Za-z0-9]+(\.[A-Za-z0-9]+)*$/;
         return email.test(this.emailOrPhone);
      },
      isValidPhone() {
         const phone = /^[\d()+-]{10,21}$/;
         return phone.test(this.emailOrPhone);
      },
      passwordError() {
         if (!this.$v.password.required) return 'The field must be filled';
         if (!this.$v.password.minLength) return 'Password must be at least 8 characters long';
         if (!this.$v.password.maxLength) return 'Password must not exceed 20 characters';
         if (!this.$v.password.hasPassword)
            return 'The password must have Latin capital and small letters,numbers and a special symbol ( ! @ # $ & + - ?)';

         return '';
      },
      disabled() {
         return !this.isEmailOrPhoneValid || this.$v.password.$invalid;
      },
      isEmailOrPhoneValid() {
         return this.isValidPhone || this.isValidEmail;
      },
      errorImailorPhone() {
         return !this.isEmailOrPhoneValid && 'Invalid Email or phone';
      },
   },
   created() {
      this.fetchUrl();
      this.fetchUrlFacebook();
      this.fetchUrlTwitter();
   },
   methods: {
      async submitHandler() {
         let provider = this.isValidPhone ? 'phone' : 'email';
         try {
            const res = await this.$http.post(`auth/user_login/${provider}`, {
               [provider]: this.emailOrPhone,
               password: this.password,
            });
            window.location.replace(appUrl + `?token=${res.data.token}`);
         } catch (e) {
            if (e?.response?.data?.message) alert(e?.response?.data?.message);
         }
         //TODO CLEAN COMMENTS
         // :errorMessage="isValidEmail ? 'invalid email' : isValidPhone ? 'invalid phone' : 'invalid filled'"
      },
      async authGoogle() {
         let url = await this.fetchUrl();
         if (this.isAuth) localStorage.isLogin = true;
         window.open(url);
      },

      async fetchUrl() {
         let response = await this.$http.post('auth/google/url');
         this.googleUrl = response.data.url;
         return response.data.url;
      },
      async authFacebook() {
         let url = await this.fetchUrlFacebook();
         if (this.isAuth) localStorage.isLogin = true;
         window.open(url);
      },

      async fetchUrlFacebook() {
         let response = await this.$http.post('auth/facebook/url');
         this.facebookUrl = response.data.url;
         return response.data.url;
      },

      async authTwitter() {
         let url = await this.fetchUrlTwitter();
         if (this.isAuth) localStorage.isLogin = true;
         window.open(url);
      },

      async fetchUrlTwitter() {
         let response = await this.$http.post('auth/twitter/url');
         this.twitterUrl = response.data.redirectUrl;
         return response.data.redirectUrl;
      },
   },
};
</script>

<style lang="sass" scoped>
.btn-nav-ragister
	text-align: left
.box-register-sleep
	max-width: 356px
	margin: 0 auto
.container-register-sleep
	width: 100%
	height: 100%
	margin: 0 auto
	max-width: 1246px
	padding: 40px 20px
	display: flex
	flex-direction: column
	@media screen and (max-width:380px)
		padding: 15px 20px
.img-sleep-register
	max-width: 128px
	margin: 0 auto
	padding-top: 30px
	padding-bottom: 24px
	@media screen and (max-width:380px)
		padding-bottom: 10px
		padding-top: 10px

.title-sleep-register
	font-size: 32px
	font-weight: 700
	line-height: 41.5px
	text-align: center
.paragraf-register
	font-size: 14px
	font-weight: 500
	line-height: 18.2px
	text-align: center
	margin-top: 16px
	margin-bottom: 16px
.footer-sleep-register
	color: #828282
	font-size: 12px
	font-weight: 400
	line-height: 16px

.button-social
	display: flex
	justify-content: space-between
.btn-reg
	width: 100%
	font-size: 14px
	font-weight: 400
.btn-reg:nth-child(2)
	margin-right: 10px
	margin-left: 10px
.register-input
	margin-bottom: 32px
.margin-inp
	margin-bottom: 16px
.validEmail
	border: 2px solid green
.validPhone
	border: 2px solid blue
</style>
